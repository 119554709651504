<template>
    <div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title m-0">
                    <h3 class="card-label m-0">
                        {{$t('MENU.credit_types')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
                <div class="card-toolbar">
                    <!--begin::Button-->
<!--                    <router-link to="/points-credits/credit-types" class="btn btn-light font-weight-bolder">-->
<!--                        <v-icon>mdi-chevron-left</v-icon>-->
<!--                        {{$t('back')}}-->
<!--                    </router-link>-->
                    <!--end::Button-->
                </div>
            </div>

            <div class="card-body">
                <b-card-body class="p-0">
                    <div class="form-group row">
                       
                        <div class="col-lg-6 mb-5">
                            <label>{{$t('credit_types.name')}}<span class="text-danger">*</span></label>
                            <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                            <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
                        </div>

                        <div class="col-lg-6 mb-5">
                            <label>{{$t('credit_types.unit')}}</label>
                            <input type="number" min="0" v-model="data.unit" class="form-control" :class="validation && validation.unit ? 'is-invalid' : ''"/>
                            <span v-if="validation && validation.unit" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.unit[0] }}
                            </span>
                        </div>

                        <div class="col-lg-6 mb-5">
                            <label>{{$t('credit_types.description')}}</label>
                            <textarea type="text" v-model="data.description" class="form-control" :class="validation && validation.description ? 'is-invalid' : ''"></textarea>
                            <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.description[0] }}
                            </span>
                        </div>

                        <div class="col-lg-3 mb-5">
                            <label>&ensp;</label>
                            <b-form-checkbox size="lg" v-model="data.allow_decimal" name="check-button" switch>{{$t('credit_types.allow_decimal')}}</b-form-checkbox>
                        </div>
                        
                        <div class="col-lg-3 mb-5">
                            <label>&ensp;</label>
                            <b-form-checkbox size="lg" v-model="data.status" name="check-button" switch>{{$t('status')}}</b-form-checkbox>
                        </div>
                        
                    </div>
                </b-card-body>

                <div class="card-footer pl-0 pr-0">
                    <div class="row">
                        <div class="col-lg-6">
                            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "from-closed-periods",
        data() {
            return {
                mainRoute: 'pointscredits/credit-types',
                mainRouteDependency: 'base/dependency',


                idEdit: this.$route.params.id ? this.$route.params.id : null,
                data: {
                    name: null,
                    unit: '',
                    status: true,
                    allow_decimal : false,
                    description: null,
                },
                isEditing: false,
                validation: null,
                status_list: [],

            };
        },

        methods: {
            save() {
                if (this.isEditing) {
                    this.update();
                } else {
                    this.create();
                }
            },

            create() {
                ApiService.post(this.mainRoute, {
                    ...this.data,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                    this.$router.push('/points-credits/credit-types');
                }).catch((error) => {
                    this.$errorAlert(error);
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },

            update() {
                ApiService.put(this.mainRoute + '/' + this.idEdit, {
                    ...this.data,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                    this.$router.push('/points-credits/credit-types');
                }).catch((error) => {
                    this.$errorAlert(error);
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },

            getStatus() {
                ApiService.get(this.mainRouteDependency + "/status_open").then((response) => {
                    this.status_list = response.data.data;
                });
            },

            getData() {
                ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
                    this.isEditing = true;
                    this.data.name = response.data.data.name;
                    this.data.unit = response.data.data.unit;
                    this.data.description = response.data.data.description;
                    this.data.status = response.data.data.status;
                    this.data.allow_decimal = response.data.data.allow_decimal;
                });
            },

        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.credit_types"), route:'/points-credits/credit-types'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);
            this.getStatus();

            if (this.idEdit) {
                this.getData();
            }

        },
    };
</script>


